import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))


// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()


function App() {

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])

    async function checkTokenAndRedirect() {
        try {
            // Make an API call to check the OpenAuth token
            const response = await fetch('/dashboard/current_user', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`, // Assuming token is stored in a variable
                },
            });

            if (response.ok) {
                // Token is valid, redirect to the desired route for authenticated users
                return '/app/dashboard';
            } else {
                // Token is invalid or expired, redirect to the login page
                return '/login';
            }
        } catch (error) {
            console.error('Error checking token:', error);
            // Handle the error as needed, for example, redirect to the login page
            return '/login';
        }
    }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/app/dashboard" : "/login"} replace />}/>

        </Routes>
      </Router>
    </>
  )
}

export default App
